/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import autoRefresh from '@/assets/utils/autoRefresh'
import Member  from '@/database/models/member';

Vue.use(Router)


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior ()
  {
    return { x: 0, y: 0 }
  },
  routes:
  [
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children:
      [
        {
          path: '/',
          name: 'page-login',
          component: () => import('@/views/full/Login.vue'),
        },
        {
          path: '/',
          name: 'unauthorized',
          component: () => import('@/views/full/Unauthorized.vue'),
        },
        // =============================================================================
        // VERIFICATION CLOTURE 
        // =============================================================================
        {
          path: '/attestationcaisse',
          name: 'cloture',
          component: () => import('@/views/full/verificationCloture.vue'),
        },
        {
          path: '/logout',
          name: 'page-logout',
          component: () => import('@/views/full/Logout.vue'),
        },
        {
          path: '/reset',
          name: 'page-login-reset',
          component: () => import('@/views/full/LostPassword.vue'),
        },
        {
          path: '/error/token',
          name: 'error-token',
          component: () => import('@/views/full/tokenError.vue'),
        },
        {
          path: '/error/404',
          name: 'error-404',
          component: () => import('@/views/full/Error404.vue'),
        },
      ]
    },
    
   

    // =============================================================================
    // PAGE LAYOUT ADMIN
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children:
      [
        {
          path: '/admin',
          name: 'home',
          component: () => import('./views/Home.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        // =============================================================================
        // VERIFICATION CLOTURE TEST
        // =============================================================================
        // {
        //   path: '/admin/attestationcloture2',
        //   name: 'cloture',
        //   component: () => import('@/views/page/verif.vue'),
          
        // },
        {
          path: '/admin/marketing',
          name: 'marketing',
          component: () => import('./views/page/marketing/marketing.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/marketing/relances',
          name: 'relances',
          component: () => import('./views/page/marketing/relances.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/marketing/fitnessboost',
          name: 'fitnessboost',
          component: () => import('./views/page/marketing/fitnessboost.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/statistique',
          name: 'statistique',
          component: () => import('./views/statistique.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/historiquerelances',
          name: 'historiqueRelances',
          component: () => import('./views/page/historique_relances.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/mbrstatistique',
          name: 'statistiques',
          component: () => import('./views/mbrstatistique.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/booking',
          name: 'bookingDay',
          component: () => import('./views/planning/booking.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/weekly',
          name: 'weekView',
          component: () => import('./views/planning/weekly.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/groups',
          name: 'groups',
          component: () => import('./views/planning/groups.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/weekly/:date',
          name: 'weeklyView',
          component: () => import('./views/planning/weekly.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/groups/:date',
          name: 'groupsView',
          component: () => import('./views/planning/groups.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/booking/:date',
          name: 'bookingDayDate',
          component: () => import('./views/planning/booking.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/calling/:date',
          name: 'calling',
          component: () => import('./views/planning/calling.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/calling/*',
          name: 'callingView',
          component: () => import('./views/planning/calling.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/bookingview/:uid',
          name: 'booking print',
          component: () => import('./views/page/booking_info.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/member',
          name: 'membre',
          component: () => import('./views/member.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/member/:uid',
          name: 'membre print',
          component: () => import('./views/page/member.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/commentary/:uid',
          name: 'commentary print',
          component: () => import('./views/page/commentary.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/declaration/:uid',
          name: 'membre declaration',
          component: () => import('./views/page/declaration.vue'),
          beforeEnter:verifLogin,
        },
        


        //Communication SMS+ MAil
        {
          path: '/admin/com/scenario',
          name: 'communication',
          component: () => import('./views/communication.vue'),
          beforeEnter:verifLogin,
        },
        // {
        //   path: '/admin/com/histo',
        //   name: 'communicationhisto',
        //   component: () => import('./views/page/com_histo.vue'),
        //   beforeEnter:verifLogin,
        // },
        {
          path: '/admin/com/member',
          name: 'groupe com select',
          component: () => import('./views/member.vue') /*import('./views/page/com_member.vue')*/,
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/sms/groupe',
          name: 'SMS groupe send',
          component: () => import('./views/page/campagne_sms.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/email/groupe',
          name: 'Email groupe send',
          component: () => import('./views/page/campagne_email.vue'),
          beforeEnter:verifLogin,
        },




        {
          path: '/admin/document',
          name: 'documents',
          component: () => import('./views/document.vue'),
          beforeEnter:verifLogin,
        },

        
        /*{
          path: '/admin/sale',
          name: 'ventes',
          component: () => import('./views/sale.vue'),
          beforeEnter:verifLogin,
        },*/
        {
          path: '/admin/invoice/:uid',
          name: 'facture print',
          component: () => import('./views/page/invoice.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/encaissement',
          name: 'encaissement view',
          component: () => import('./views/encaissement.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        // {
        //   path: '/admin/abonnements',
        //   name: 'abonnements',
        //   component: () => import('./views/page/abonnements.vue'),
        //   beforeEnter:verifLogin,
        // },
        {
          path: '/admin/export/contable',
          name: 'encaissement contable',
          component: () => import('./views/exportContable.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/option',
          name: 'option',
          component: () => import('./views/option.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/terminal/:uid',
          name: 'terminal print',
          component: () => import('./views/page/terminal.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '',
          name: 'facturation',
          component: () => import('./views/page/facturation_versionH.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true },
          children:[
            {
              path: '/admin/facturesView/:uid',
              name: 'factures View',
              component: () => import('./views/page/facturationViews/factures.vue'),
              beforeEnter:verifLogin,
              meta: { authComptable: true }
            },
            {
              path: '/admin/sellsView/:uid',
              name: 'sells View',
              component: () => import('./views/page/facturationViews/sells.vue'),
              beforeEnter:verifLogin,
              meta: { authComptable: true }
            },
            {
              path: '/admin/teamView/:uid',
              name: 'team View',
              component: () => import('./views/page/facturationViews/team.vue'),
              beforeEnter:verifLogin,
              meta: { authComptable: true }
            },
            
          ]
        },
        {
          path: '/admin/machine/:uid',
          name: 'machine print',
          component: () => import('./views/page/machine.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/file/:url',
          name: 'file view print',
          component: () => import('./views/page/file_viewer.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/checkout/:mbr/abo',
          name: 'webapp checkout abonnement',
          component: () => import('./views/page/checkout/abonnement.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/checkout/:mbr/:type',
          name: 'webapp checkout facture/avoir',
          component: () => import('./views/page/checkout/fa_av.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/sepa/:mbr',
          name: 'webapp sepa mollie',
          component: () => import('./views/page/checkout/sepa.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/creance',
          name: 'webapp creance',
          component: () => import('./views/page/creance.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },

        //Option page
        {
          path: '/admin/option/login',
          name: 'option login et password',
          component: () => import('./views/page/options/login.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/option/equipe',
          name: 'option equipe',
          component: () => import('./views/page/options/equipe.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/structure',
          name: 'option structure',
          component: () => import('./views/page/options/structure.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/rgpd',
          name: 'option rgpd',
          component: () => import('./views/page/options/rgpd.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/terminal',
          name: 'option terminal',
          component: () => import('./views/page/options/terminal.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/catalogue',
          name: 'option catalogue',
          component: () => import('./views/page/options/catalogue.vue'),
          beforeEnter:verifLogin,
          meta: { authComptable: true }
        },
        {
          path: '/admin/option/cgv',
          name: 'option cgv',
          component: () => import('./views/page/options/cgv.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/essaipro',
          name: 'option provenance essai',
          component: () => import('./views/page/options/essai_provenance.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/planning',
          name: 'option planning',
          component: () => import('./views/page/options/planning.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/machine/:uid',
          name: 'option machine',
          component: () => import('./views/page/options/machine.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/fermeture',
          name: 'option fermeture',
          component: () => import('./views/page/options/fermeture.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/declaration',
          name: 'option declaration',
          component: () => import('./views/page/options/declaration.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/externe',
          name: 'liens externes',
          component: () => import('./views/page/options/externe.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/conseils',
          name: 'conseils marketing',
          component: () => import('./views/page/options/conseils_marketing.vue'),
          beforeEnter:verifLogin,
        },
        {
          path: '/admin/option/e-mails-externes',
          name: 'paramétrages adesses externes',
          component: () => import('./views/page/options/e-mails_externes.vue'),
          beforeEnter:verifLogin,
        },
      ],
    },


    // =============================================================================
    // Redirect to 404 page, if no match found
    // =============================================================================
    {
      path: '*',
      redirect: '/error/404'
    }
  ],
})

//Verification de login
function verifLogin(to, from, next)
{
  //verif local local
  if(!Vue.prototype.$srvApi.isLocalLogin() )
    router.push('/');

  const user = Vue.prototype.$srvApi.getLoginUserUid()
  if(user == null)
    router.push('/');
  else {
    Member.getMemory(user, (mbr)=>
    {
      if(mbr.type.toLowerCase() == 'c') {
        if (to.meta.authComptable) {
          return next();
        } else {
          next({ name: 'unauthorized' })
        }
      } else return next();
    });
  }

  //desactive refresh
  if(to.name != from.name )
    autoRefresh.SetCallback(undefined)

  return next();
}



router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
